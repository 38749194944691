import React from 'react'
import Accounts from './Accounts'

function HomePage() {
  return (
    <div className="container py-3">
      <Accounts />
    </div>
  )
}

export default HomePage
