import React from 'react'

function NotFound() {
  return (
    <div className="container py-3">
      <div className="alert alert-danger my-0">Page not found!</div>
    </div>
  )
}

export default NotFound
